var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "fill-height": "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("h1", [
              _vm._v(
                _vm._s(_vm.$t("receiptForm.informationAboutTaxtCardTrip")) + " "
              )
            ])
          ]),
          _c(
            "v-col",
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        { staticClass: "mt-3" },
                        [
                          _c("v-card-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("receiptForm.companyInformation")
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: [
                                            _vm.requiredRule,
                                            _vm.businessIdRule
                                          ],
                                          label: this.$t(
                                            "receiptForm.companyId"
                                          ),
                                          placeholder: "1234567-8",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.tripInfo.companyId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tripInfo,
                                              "companyId",
                                              $$v
                                            )
                                          },
                                          expression: "tripInfo.companyId"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: this.$t(
                                            "receiptForm.companyName"
                                          ),
                                          rules: [
                                            _vm.requiredRule,
                                            _vm.maxLengthRule(100)
                                          ],
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.tripInfo.companyName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tripInfo,
                                              "companyName",
                                              $$v
                                            )
                                          },
                                          expression: "tripInfo.companyName"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: this.$t("receiptForm.email"),
                                          rules: [
                                            _vm.requiredRule,
                                            _vm.emailRule
                                          ],
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.tripInfo.email,
                                          callback: function($$v) {
                                            _vm.$set(_vm.tripInfo, "email", $$v)
                                          },
                                          expression: "tripInfo.email"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: this.$t(
                                            "receiptForm.phoneNumber"
                                          ),
                                          rules: [
                                            _vm.requiredRule,
                                            _vm.phoneNumberRule
                                          ],
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.tripInfo.phoneNumber,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tripInfo,
                                              "phoneNumber",
                                              $$v
                                            )
                                          },
                                          expression: "tripInfo.phoneNumber"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    _vm._l(_vm.tripInfo.fares, function(fare, i) {
                      return _c(
                        "v-card",
                        { key: i, staticClass: "mt-3" },
                        [
                          _c(
                            "v-card-title",
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("receiptForm.tripInformation")
                                  ) +
                                  ": " +
                                  _vm._s(_vm.$t("receiptForm.customer")) +
                                  " " +
                                  _vm._s(i + 1) +
                                  " "
                              ),
                              _c(
                                "v-icon",
                                {
                                  attrs: { right: "", color: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteFare(i)
                                    }
                                  }
                                },
                                [_vm._v(" delete ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-file-input", {
                                        attrs: {
                                          accept: "image/png, image/jpeg",
                                          label: _vm.$t(
                                            "receiptForm.pictureOfReceipt"
                                          ),
                                          v: "",
                                          filled: "",
                                          "prepend-icon": "mdi-camera"
                                        },
                                        model: {
                                          value: fare.receipt,
                                          callback: function($$v) {
                                            _vm.$set(fare, "receipt", $$v)
                                          },
                                          expression: "fare.receipt"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          label: _vm.$t(
                                            "receiptForm.iDontHaveAReceipt"
                                          )
                                        },
                                        model: {
                                          value: fare.noReceipt,
                                          callback: function($$v) {
                                            _vm.$set(fare, "noReceipt", $$v)
                                          },
                                          expression: "fare.noReceipt"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              !!fare.receipt || fare.noReceipt
                                ? _c(
                                    "v-skeleton-loader",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4", sm: "2" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "receiptForm.travelDate"
                                                  ),
                                                  rules: [
                                                    _vm.requiredRule,
                                                    _vm.dateTimeRule
                                                  ],
                                                  type: "date",
                                                  required: ""
                                                },
                                                model: {
                                                  value: fare.tripDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      fare,
                                                      "tripDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "fare.tripDate"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4", sm: "2" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "receiptForm.startTime"
                                                  ),
                                                  rules: [
                                                    _vm.requiredRule,
                                                    _vm.timeRule
                                                  ],
                                                  type: "time",
                                                  required: ""
                                                },
                                                model: {
                                                  value: fare.started,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      fare,
                                                      "started",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "fare.started"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4", sm: "2" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "receiptForm.endTime"
                                                  ),
                                                  rules: [
                                                    _vm.requiredRule,
                                                    _vm.timeRule
                                                  ],
                                                  type: "time",
                                                  required: ""
                                                },
                                                model: {
                                                  value: fare.ended,
                                                  callback: function($$v) {
                                                    _vm.$set(fare, "ended", $$v)
                                                  },
                                                  expression: "fare.ended"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "receiptForm.region"
                                                  ),
                                                  items: [
                                                    "Etelä-Karjala",
                                                    "Uusimaa"
                                                  ],
                                                  rules: [_vm.requiredRule],
                                                  required: ""
                                                },
                                                model: {
                                                  value: fare.region,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      fare,
                                                      "region",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "fare.region"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4", sm: "2" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "receiptForm.carNumber"
                                                  ),
                                                  rules: [
                                                    _vm.requiredRule,
                                                    _vm.maxLengthRule(8)
                                                  ],
                                                  required: ""
                                                },
                                                model: {
                                                  value: fare.carNumber,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      fare,
                                                      "carNumber",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "fare.carNumber"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4", sm: "2" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "receiptForm.orderId"
                                                  ),
                                                  rules: [
                                                    _vm.requiredRule,
                                                    _vm.maxLengthRule(12)
                                                  ],
                                                  required: ""
                                                },
                                                model: {
                                                  value: fare.orderId,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      fare,
                                                      "orderId",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "fare.orderId"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "3" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "receiptForm.startingAddress"
                                                  ),
                                                  rules: [
                                                    _vm.maxLengthRule(100)
                                                  ],
                                                  required: ""
                                                },
                                                model: {
                                                  value: fare.startingPoint,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      fare,
                                                      "startingPoint",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "fare.startingPoint"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "3" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "receiptForm.destinationAddress"
                                                  ),
                                                  rules: [
                                                    _vm.maxLengthRule(100)
                                                  ],
                                                  required: ""
                                                },
                                                model: {
                                                  value: fare.destination,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      fare,
                                                      "destination",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "fare.destination"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4", sm: "2" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "receiptForm.deductible"
                                                  ),
                                                  rules: [
                                                    _vm.decimalRule,
                                                    _vm.maxLengthWithoutDecimalSeparatorRule(
                                                      6
                                                    )
                                                  ],
                                                  suffix: "€",
                                                  required: "",
                                                  type: "number"
                                                },
                                                model: {
                                                  value: fare.deductible,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      fare,
                                                      "deductible",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "fare.deductible"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4", sm: "2" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "receiptForm.startFee"
                                                  ),
                                                  rules: [
                                                    _vm.decimalRule,
                                                    _vm.maxLengthWithoutDecimalSeparatorRule(
                                                      8
                                                    )
                                                  ],
                                                  suffix: "€",
                                                  required: "",
                                                  type: "number"
                                                },
                                                model: {
                                                  value: fare.startFee,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      fare,
                                                      "startFee",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "fare.startFee"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4", sm: "2" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "receiptForm.waitFee"
                                                  ),
                                                  rules: [
                                                    _vm.decimalRule,
                                                    _vm.maxLengthWithoutDecimalSeparatorRule(
                                                      8
                                                    )
                                                  ],
                                                  suffix: "€",
                                                  required: "",
                                                  type: "number"
                                                },
                                                model: {
                                                  value: fare.waitFee,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      fare,
                                                      "waitFee",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "fare.waitFee"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c("v-label", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "receiptForm.fare"
                                                              )
                                                            ) +
                                                            " 1: "
                                                        )
                                                      ]),
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          suffix: "km",
                                                          type: "number",
                                                          rules: [
                                                            _vm.decimalRule,
                                                            _vm.maxLengthWithoutDecimalSeparatorRule(
                                                              8
                                                            )
                                                          ]
                                                        },
                                                        model: {
                                                          value:
                                                            fare.fare1Kilometers,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              fare,
                                                              "fare1Kilometers",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "fare.fare1Kilometers"
                                                        }
                                                      }),
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          suffix: "€",
                                                          type: "number",
                                                          rules: [
                                                            _vm.decimalRule,
                                                            _vm.maxLengthWithoutDecimalSeparatorRule(
                                                              8
                                                            )
                                                          ]
                                                        },
                                                        model: {
                                                          value: fare.fare1Sum,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              fare,
                                                              "fare1Sum",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "fare.fare1Sum"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c("v-label", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "receiptForm.fare"
                                                              )
                                                            ) +
                                                            " 2: "
                                                        )
                                                      ]),
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          rules: [
                                                            _vm.decimalRule,
                                                            _vm.maxLengthWithoutDecimalSeparatorRule(
                                                              8
                                                            )
                                                          ],
                                                          suffix: "km",
                                                          type: "number"
                                                        },
                                                        model: {
                                                          value:
                                                            fare.fare2Kilometers,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              fare,
                                                              "fare2Kilometers",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "fare.fare2Kilometers"
                                                        }
                                                      }),
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          rules: [
                                                            _vm.decimalRule,
                                                            _vm.maxLengthWithoutDecimalSeparatorRule(
                                                              8
                                                            )
                                                          ],
                                                          suffix: "€",
                                                          type: "number"
                                                        },
                                                        model: {
                                                          value: fare.fare2Sum,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              fare,
                                                              "fare2Sum",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "fare.fare2Sum"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c("v-label", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "receiptForm.extraFees"
                                                              )
                                                            ) +
                                                            ": "
                                                        )
                                                      ]),
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "receiptForm.aidFee"
                                                          ),
                                                          rules: [
                                                            _vm.decimalRule,
                                                            _vm.maxLengthWithoutDecimalSeparatorRule(
                                                              8
                                                            )
                                                          ],
                                                          suffix: "€",
                                                          type: "number"
                                                        },
                                                        model: {
                                                          value: fare.aidFee,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              fare,
                                                              "aidFee",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "fare.aidFee"
                                                        }
                                                      }),
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "receiptForm.serviceFee"
                                                          ),
                                                          rules: [
                                                            _vm.decimalRule,
                                                            _vm.maxLengthWithoutDecimalSeparatorRule(
                                                              8
                                                            )
                                                          ],
                                                          suffix: "€",
                                                          type: "number"
                                                        },
                                                        model: {
                                                          value:
                                                            fare.serviceFee,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              fare,
                                                              "serviceFee",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "fare.serviceFee"
                                                        }
                                                      }),
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "receiptForm.stairFee"
                                                          ),
                                                          rules: [
                                                            _vm.decimalRule,
                                                            _vm.maxLengthWithoutDecimalSeparatorRule(
                                                              8
                                                            )
                                                          ],
                                                          suffix: "€",
                                                          type: "number"
                                                        },
                                                        model: {
                                                          value: fare.stairFee,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              fare,
                                                              "stairFee",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "fare.stairFee"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c("v-label", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "receiptForm.total"
                                                              )
                                                            ) +
                                                            ": "
                                                        )
                                                      ]),
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          value: "0",
                                                          suffix: "km",
                                                          disabled: ""
                                                        }
                                                      }),
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          value: "0",
                                                          suffix: "€",
                                                          disabled: ""
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.tripInfo.fares.push({})
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-plus")
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm.$t("receiptForm.addTrip")) + " "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.$t("receiptForm.additionalInformation")
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "v-card-text",
                            { staticClass: "pb-0" },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  rules: [_vm.maxLengthRule(200)],
                                  placeholder: _vm.$t(
                                    "receiptForm.additionalInfoPlaceHolder"
                                  ),
                                  solo: "",
                                  "auto-grow": "",
                                  rows: "2"
                                },
                                model: {
                                  value: _vm.tripInfo.comment,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tripInfo, "comment", $$v)
                                  },
                                  expression: "tripInfo.comment"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "success" },
                                  on: { click: _vm.sendForm }
                                },
                                [_vm._v(" Lähetä lomake ")]
                              ),
                              _c("v-spacer")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }