import moment from "moment";

let requiredRule = v => (!!v || v === 0) || "Value is required";
let digitRule = v => /^\d+$/.test(v) || "Value must contain only digits";
let decimalRule = v => /^\d+((.|,)\d+)?$/.test(v) || "Value must be decimal value";
let dateTimeRule = v => isValidDateTime(v) || "Value is not valid datetime";
let emailRule = v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid";
let phoneNumberRule = v => !v || /^(358)\d{5,11}$/.test(v) || "Phone number must be valid and start with 358";
let businessIdRule = v => /^\d{7}-\d$/.test(v) || "Business id must be of form 1234567-8";
let timeRule = v => !v || /^\d{2}.\d{2}$/.test(v) || "Value is not a valid time";
let isValidDateTime = (value) => moment(value).isValid();

export default {
  data () {
    return {
      requiredRule: requiredRule,
      digitRule: digitRule,
      decimalRule: decimalRule,
      dateTimeRule: dateTimeRule,
      emailRule: emailRule,
      phoneNumberRule: phoneNumberRule,
      businessIdRule: businessIdRule,
      timeRule: timeRule,
    };
  },
  methods: {
    maxLengthRule (maxLength) {
      return v => (v || "").toString().length <= maxLength ||
                `Value cannot be longer than ${maxLength} characters`;
    },
    maxLengthWithoutDecimalSeparatorRule (maxLength) {
      return v => (v || "").toString().replace(".", "").replace(",", "").length <= maxLength ||
                `Value cannot be longer than ${maxLength} characters without decimal point`;
    },
    valueInRangeRule (min, max) {
      return v => (!isNaN(v) && v <= max && v >= min) ||
                `Value must be in range from ${min} to ${max}`;
    }
  }
};
