import Vue from "vue";
import Router from "vue-router";
// import Home from "./views/Home.vue";
import ReceiptForm from "./views/ReceiptForm";


Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
/*     {
      path: "/",
      name: "Home",
      component: Home
    }, */
    {
      path: "/",
      name: "Home",
      component: ReceiptForm
    }
  ]
});

export default router;

router.beforeEach(async (to, from, next) => {
  const { authorize } = to.meta;
  // const user = await Vue.prototype.$AuthService.getUser();
  const user = null;
  if (authorize) {
    if (!user) {
      alert("You are not authenticated.");
      return next({ name: "Home" });
    }

    const canAccess = Vue.prototype.$AuthService.canAccessSite(authorize.role);

    if (!canAccess) {
      alert("You do not have permission to enter this page.");
      return next({ name: "Home" });
    }
  }
  next();
});
