const en = {
  common: {
    addNew: "Add new",
    close: "Close",
    search: "Search",
    cancel: "Cancel",
    filter: "Filter",
    saveChanges: "Save changes",
    tba: "TBA",
    reason: "Reason: {reason}"
  },
  login: {
    loginButton: "Click here to login",
    logOut: "Log out",
    enablePopUps: "Please enable pop-ups if permission is asked by browser. Pop-up is required for login."
  },
  receiptForm: {
    informationAboutTaxtCardTrip: "Information about taxi card trip",
    companyInformation: "Company information",
    companyId: "Business id",
    companyName: "Company name",
    email: "Email",
    phoneNumber: "Phone number",
    tripInformation: "Trip information",
    customer: "Customer",
    pictureOfReceipt: "Picture of receipt",
    iDontHaveAReceipt: "I don't have a receipt",
    travelDate: "Travel date",
    startTime: "Start time",
    endTime: "End time",
    region: "Region",
    carNumber: "Car number",
    startingAddress: "Starting address",
    destinationAddress: "Destination address",
    deductible: "Deductible",
    orderId: "Order id",
    startFee: "Start fee",
    waitFee: "Wait fee",
    aidFee: "Aid fee",
    serviceFee: "Service fee",
    stairFee: "Stair fee",
    fare: "Fare",
    extraFees: "Extra fees",
    total: "Total",
    addTrip: "Add trip",
    additionalInformation: "Additional information",
    additionalInfoPlaceHolder: "Write any necessary additional information here",
  }
};

export default en;
