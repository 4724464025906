<template>
    <v-container fill-height>
        <v-row justify="center">
        <v-col cols="12"><h1>{{$t('receiptForm.informationAboutTaxtCardTrip')}} </h1></v-col>
        <v-col>
        <v-form ref="form">
            <v-col>
            <v-card
            class="mt-3"
            >
            <v-card-title>
                {{$t('receiptForm.companyInformation')}}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols=6 sm=3>
                        <v-text-field
                            v-model="tripInfo.companyId"
                            :rules="[ requiredRule, businessIdRule ]"
                            :label="this.$t('receiptForm.companyId')"
                            placeholder="1234567-8"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols=6 sm=3>
                        <v-text-field
                            v-model="tripInfo.companyName"
                            :label="this.$t('receiptForm.companyName')"
                            :rules="[ requiredRule, maxLengthRule(100) ]"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols=6 sm=3>
                        <v-text-field
                            v-model="tripInfo.email"
                            :label="this.$t('receiptForm.email')"
                            :rules="[ requiredRule, emailRule ]"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols=6 sm=3>
                        <v-text-field
                            v-model="tripInfo.phoneNumber"
                            :label="this.$t('receiptForm.phoneNumber')"
                            :rules="[ requiredRule, phoneNumberRule ]"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                </v-card-text>
            </v-card>
            </v-col>

            <v-col>
            <v-card 
            v-for="(fare, i) in tripInfo.fares"
            :key=i
            class="mt-3">
                <v-card-title>
                    {{$t('receiptForm.tripInformation')}}: {{$t('receiptForm.customer')}} {{i+1}}
                    <v-icon right
                        @click="deleteFare(i)"
                        color="primary">
                        delete
                    </v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                    <v-file-input
                        v-model="fare.receipt"
                        accept="image/png, image/jpeg"
                        :label="$t('receiptForm.pictureOfReceipt')"
                        v
                        filled
                        prepend-icon="mdi-camera"
                    ></v-file-input>
                    </v-col>
                    <v-col>
                        <v-checkbox
                            v-model="fare.noReceipt"
                            :label="$t('receiptForm.iDontHaveAReceipt')"
                        ></v-checkbox>
                    </v-col>
                    </v-row>
                    <v-skeleton-loader v-if="!!fare.receipt || fare.noReceipt">
                        <v-row>
                            <v-col cols=4 sm=2>
                                <v-text-field
                                    v-model="fare.tripDate"
                                    :label="$t('receiptForm.travelDate')"
                                    :rules="[ requiredRule, dateTimeRule ]"
                                    type="date"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols=4 sm=2>
                                <v-text-field
                                    v-model="fare.started"
                                    :label="$t('receiptForm.startTime')"
                                    :rules="[ requiredRule, timeRule ]"
                                    type="time"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols=4 sm=2>
                                <v-text-field
                                    v-model="fare.ended"
                                    :label="$t('receiptForm.endTime')"
                                    :rules="[ requiredRule, timeRule]"
                                    type="time"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols=12 sm=6>
                                <v-select
                                    v-model="fare.region"
                                    :label="$t('receiptForm.region')"
                                    :items="['Etelä-Karjala', 'Uusimaa']"
                                    :rules="[ requiredRule ]"
                                    required
                                ></v-select>
                            </v-col>
                            <v-col cols=4 sm=2>
                                <v-text-field
                                    v-model="fare.carNumber"
                                    :label="$t('receiptForm.carNumber')"
                                    :rules="[ requiredRule, maxLengthRule(8) ]"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col  cols=4 sm=2>
                                <v-text-field
                                    v-model="fare.orderId"
                                    :label="$t('receiptForm.orderId')"
                                    :rules="[ requiredRule, maxLengthRule(12) ]"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col  cols=12 sm=3>
                                <v-text-field
                                    v-model="fare.startingPoint"
                                    :label="$t('receiptForm.startingAddress')"
                                    :rules="[ maxLengthRule(100) ]"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols=12 sm=3>
                                <v-text-field
                                    v-model="fare.destination"
                                    :label="$t('receiptForm.destinationAddress')"
                                    :rules="[ maxLengthRule(100) ]"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols=4 sm=2>
                                <v-text-field
                                    v-model="fare.deductible"
                                    :label="$t('receiptForm.deductible')"
                                    :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(6) ]"
                                    suffix="€"
                                    required
                                    type="number"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols=4 sm=2>
                                <v-text-field
                                    v-model="fare.startFee"
                                    :label="$t('receiptForm.startFee')"
                                    :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
                                    suffix="€"
                                    required
                                    type="number"
                                ></v-text-field>
                            </v-col>
                            <v-col cols=4 sm=2>
                                <v-text-field
                                    v-model="fare.waitFee"
                                    :label="$t('receiptForm.waitFee')"
                                    :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
                                    suffix="€"
                                    required
                                    type="number"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols=6 sm=2>
                            <v-card>
                                <v-card-text>
                                <v-label>
                                    {{$t('receiptForm.fare')}} 1:
                                </v-label>
                                <v-text-field
                                    v-model="fare.fare1Kilometers"
                                    suffix="km"
                                    type="number"
                                    :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
                                ></v-text-field>
                                <v-text-field
                                    v-model="fare.fare1Sum"
                                    suffix="€"
                                    type="number"
                                    :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
                                ></v-text-field>
                                </v-card-text>
                            </v-card>
                            </v-col>
                            <v-col cols=6 sm=2>
                                <v-card>
                                    <v-card-text>
                                    <v-label>
                                    {{$t('receiptForm.fare')}} 2:
                                    </v-label>
                                    <v-text-field
                                        v-model="fare.fare2Kilometers"
                                        :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
                                        suffix="km"
                                        type="number"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="fare.fare2Sum"
                                        :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
                                        suffix="€"
                                        type="number"
                                    ></v-text-field>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols=6 sm=2>
                                <v-card>
                                    <v-card-text>
                                    <v-label>
                                        {{$t('receiptForm.extraFees')}}:
                                    </v-label>
                                    <v-text-field
                                        v-model="fare.aidFee"
                                        :label="$t('receiptForm.aidFee')"
                                        :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
                                        suffix="€"
                                        type="number"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="fare.serviceFee"
                                        :label="$t('receiptForm.serviceFee')"
                                        :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
                                        suffix="€"
                                        type="number"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="fare.stairFee"
                                        :label="$t('receiptForm.stairFee')"
                                        :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
                                        suffix="€"
                                        type="number"
                                    ></v-text-field>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols=6 sm=2>
                                <v-card>
                                    <v-card-text>
                                    <v-label>
                                        {{$t('receiptForm.total')}}:
                                    </v-label>
                                    <v-text-field
                                        value=0
                                        suffix="km"
                                        disabled
                                    ></v-text-field>
                                    <v-text-field
                                        value=0
                                        suffix="€"
                                        disabled
                                    ></v-text-field>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-skeleton-loader>
                </v-card-text>
            </v-card>
            </v-col>
            <v-col cols=12>
                <v-btn 
                    @click="tripInfo.fares.push({})"
                    color="primary">
                    <v-icon left>mdi-plus</v-icon>
                    {{$t('receiptForm.addTrip')}}
                </v-btn>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>
                    {{this.$t('receiptForm.additionalInformation')}}
                    </v-card-title>
                    <v-card-text class="pb-0">
                        <v-textarea
                            v-model="tripInfo.comment"
                            :rules="[maxLengthRule(200)]"
                            :placeholder="$t('receiptForm.additionalInfoPlaceHolder')"
                            solo
                            auto-grow
                            rows="2"
                        ></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="success"
                            @click="sendForm"
                        >
                            Lähetä lomake
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-form>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
import combineRepository from '../data/combineRepository'
import InputRuleMixin from '../rules/InputRuleMixin'

export default {
    data: () => ({
        tripInfo: {
            companyId: '',
            companyIdRules : [
                v => !!v || 'login.loginButton'
            ],
            fares: [{}]
        }
    }),
    mixins: [InputRuleMixin],
    methods: {
        async sendForm() {
            
            if (!this.$refs.form.validate() 
            || this.tripInfo.fares.length == 0
            || this.tripInfo.fares.some(fare => (!fare.noReceipt && fare.receipt == null))){
                // TODO: Direct user's attention to validation errors
                return;
            }

            const tripModel = {
                comment: this.tripInfo.comment,
                companyId: this.tripInfo.companyId,
                companyName: this.tripInfo.companyName,
                email: this.tripInfo.email,
                phoneNumber: this.tripInfo.phoneNumber,
                fares: []
            };

            const receiptToBase64 = (receipt) => {
                return new Promise((resolve) => {
                    if (!receipt){
                        resolve();
                    }
                    
                    const reader = new FileReader();
                    reader.onerror = () => {
                        reader.abort();
                        resolve();
                    }
                    reader.onload = () => {
                        var base64String = reader.result
                            .replace("data:", "")
                            .replace(/^.+,/, "");
                        resolve(base64String);
                    }
                    reader.readAsDataURL(receipt);
                });
            }

            for await (const fare of this.tripInfo.fares){
                var modelFare = {
                    aidFee: fare.aidFee,
                    carNumber: fare.carNumber,
                    deductible: fare.deductible,
                    destination: fare.destination,
                    ended: fare.ended,
                    fare1Kilometers: fare.fare1Kilometers,
                    fare1Sum: fare.fare1Sum,
                    fare2Kilometers: fare.fare2Kilometers,
                    fare2Sum: fare.fare2Sum,
                    noReceipt: fare.noReceipt,
                    orderId: fare.orderId,
                    region: fare.region,
                    serviceFee: fare.serviceFee,
                    stairFee: fare.stairFee,
                    started: fare.started,
                    startFee: fare.startFee,
                    startingPoint: fare.startingPoint,
                    tripDate: fare.tripDate,
                    waitFee: fare.waitFee
                };
                if (fare.receipt){
                    var receipt = await receiptToBase64(fare.receipt);
                    modelFare.receipt = receipt;
                    modelFare.receiptType = fare.receipt.type === 'image/png' ?
                        'PNG' :
                        'JPEG';
                }
                tripModel.fares.push(modelFare);
            }

            await combineRepository.createReceipt(tripModel);
            this.tripInfo.fares = [{}]
            this.tripInfo.comment = ""
        },
        deleteFare(i) {
            this.tripInfo.fares = this.tripInfo.fares.filter((_fare, index) => i != index);
        }
    }
}
</script>

<style>

</style>
