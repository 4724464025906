var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.isLoggedIn
        ? _c("Header", { on: { sideMenuToggled: _vm.toggleSideMenu } })
        : _vm._e(),
      _c(
        "v-main",
        [
          _vm.isLoggedIn
            ? _c(
                "v-container",
                { attrs: { fluid: "" } },
                [_c("router-view")],
                1
              )
            : _c("Login", { on: { loggedIn: _vm.setUserContext } })
        ],
        1
      ),
      _c(
        "v-footer",
        { attrs: { padless: "" } },
        [
          _c("v-col", { staticClass: "text-center", attrs: { cols: "12" } }, [
            _vm._v(" " + _vm._s(new Date().getFullYear()) + " - "),
            _c("strong", [_vm._v("Router Group")])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }