import axios from "axios";
import { Util } from "@microsoft/applicationinsights-web";
// import AuthService from "../msal";

const combineUrl = process.env.VUE_APP_SERVICE_URL;

// const authService = new AuthService();

async function getAccessToken () {
  return "foo"; // TODO: Tämä lokaalisti kuntoon?
  // const token = await authService.getToken(authService.applicationConfig.backendScopes);
  // return token.accessToken;
}

let defaultConfig = async (config) => {
  config.headers["Request-Id"] = Util.generateW3CId();
  config.headers.Authorization = `Bearer ${await getAccessToken()}`;
  config.headers["RG-TenantId"] = "ltx" // TODO: Tämä jostain
  return config;
};

let defaultErrorHandler = (error) => {
  return Promise.reject(error);
};

var combineService = axios.create({ baseURL: combineUrl });
combineService.interceptors.request.use(defaultConfig, defaultErrorHandler);

export { combineService };