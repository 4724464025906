import { combineService } from "./repository";

const version = "/v1";

export default {
  async createReceipt (payload){
    var url = `${version}/combine/driverreceipts`;
    return combineService.post(url , payload);
  },
};
