
import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "@/assets/translations/en";
import fi from "@/assets/translations/fi";

Vue.use(VueI18n);

export default new VueI18n({
  fallbackLocale: "fi",
  messages: { fi, en }
});
