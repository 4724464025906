var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { attrs: { app: "", "clipped-left": "", color: "black", dark: "" } },
    [
      _c("v-spacer"),
      _c(
        "v-toolbar-title",
        { staticClass: "ml-0 pl-3", staticStyle: { "max-width": "800px" } },
        [
          _c("img", {
            staticClass: "site-logo hidden-xs-only",
            attrs: {
              src: require("@/assets/logo.png"),
              alt: "Router Group logo"
            }
          }),
          _c("a", { attrs: { rel: "author", href: "/" } }, [
            _c("span", { staticClass: "site-title" }, [
              _vm._v(_vm._s(_vm.header))
            ])
          ])
        ]
      ),
      _c("v-spacer"),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { icon: "" }, on: { click: _vm.logOut } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-logout")])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("login.logOut")))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }