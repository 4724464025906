<template>
    <v-container fluid>
        <v-row align="center" justify="center">
            <img
                class="hidden-sm-and-up"
                style="max-width:80vw"
                src="@/assets/logo.png"
                alt="Router Group logo"
            />
            <img class="hidden-xs-only"
                style="max-height:50vh;"
                src="@/assets/logo_full.png"
                alt="Router Group logo"
            />
        </v-row>
        <v-row align="center" justify="center">
            <h1>Driver Portal</h1>
        </v-row>
        <v-row align="center" justify="center" class="mt-10">
            <v-btn large color="primary" @click="login">{{$t("login.loginButton")}}</v-btn>
        </v-row>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
export default {
  data () {
    return {
      loading: false
    };
  },
  methods: {
    async login () {
      this.$AuthService.login().then(() => {
        this.$emit("loggedIn");
      });

    }
  }
};
</script>

<style>

</style>
