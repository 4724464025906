import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import vuetify from './plugins/vuetify';
import i18n from "./plugins/i18n";
import AuthService from "./msal";

Vue.prototype.$AuthService = new AuthService()

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
