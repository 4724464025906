<template>
	<v-app-bar app clipped-left color="black" dark>
		<!-- <v-app-bar-nav-icon
			@click.stop="$emit('sideMenuToggled')"
			class="navigation-toggle hidden-lg-and-up"
		></v-app-bar-nav-icon> -->
		<v-spacer></v-spacer>
		<v-toolbar-title style="max-width: 800px" class="ml-0 pl-3">
			<img
				class="site-logo hidden-xs-only"
				src="@/assets/logo.png"
				alt="Router Group logo"
			/>
			<a rel="author" href="/">
				<span class="site-title">{{ header }}</span>
			</a>
		</v-toolbar-title>
		<v-spacer></v-spacer>
		<!-- <v-menu offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
				text
				v-bind="attrs"
				v-on="on"
				>
				{{language}}
				<v-icon>mdi-chevron-down</v-icon>
				</v-btn>
			</template>
			<v-list>
				<v-list-item
				v-for="(language, index) in languages"
				:key="index"
				@click="changeLanguage(language.value)"
				>
				<v-list-item-title>{{ language.text }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu> -->
		<v-tooltip bottom>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					icon
					@click="logOut"
					v-bind="attrs"
					v-on="on"
				>
					<v-icon>mdi-logout</v-icon>
				</v-btn>
			</template>
			<span>{{$t("login.logOut")}}</span>
		</v-tooltip>
	</v-app-bar>
</template>

<script>
export default {
  data: () => ({
    header: "",
    languages: [{ text: "English", value: "en" }, { text: "Finnish", value: "fi" }]
  }),
  computed: {
    language () {
      let locale = this.$i18n.locale;
      return locale.split("-")[0].trim();
    }
  },
  created () {
    let version = this.getEnvironment();
    this.header = `Driver Portal${version}`;
  },
  methods: {
    getEnvironment () {
      const path = window.location.href;

      if (path.indexOf("localhost") > -1) {
        return " - dev";
      }

      let version = "";

      if (path.split(".")[1] === "dev") {
        version = " - development";
      } else if (path.split(".")[1] === "test") {
        version = " - test";
      }

      return version;
    },
    changeLanguage (language) {
      this.$i18n.locale = language;
      this.$vuetify.lang.current = language;
    },
    logOut () {
      this.$AuthService.logout();
    }
  }
};
</script>

<style scoped>
.navigation-toggle {
	float: left;
}

.site-logo {
	float: left;
	height: 56px;
	margin-left: 10px;
}

.site-title {
	margin-top: 5px;
	font-size: 30px;
	margin-left: 15px;
	line-height: 50px;
	letter-spacing: -1px;
	white-space: nowrap;
	float: left;
}

@media (max-width: 960px) {
	.site-title {
		margin-left: 10px;
	}
}

*::after {
	content: "";
	clear: both;
	display: table;
}
.site-title,
.site-title:visited {
	color: white;
}
</style>
