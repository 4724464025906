import * as msal from '@azure/msal-browser'
import config from '../config'

export default class AuthService {
  constructor() {
    this.applicationConfig = {
      backendScopes: {
        scopes: [`${config.clientId}/.default`]
      }
    };

    this.msalConfig = {
        auth: {
          clientId: config.clientId,
          authority: config.authority,
          redirectUri: config.redirectUri
        },
        cache: {
          cacheLocation: "sessionStorage",
          storeAuthStateInCookie: false
        }
    }

    this.app = new msal.PublicClientApplication(
      this.msalConfig)
  }

  login() {
    const loginRequest = {
        scopes: ["User.Read"]
    };
    return this.app.loginPopup(loginRequest).then(
      resp => {
        this.accountId = resp.account.homeAccountId;
        this.app.setActiveAccount(resp.account);
      },
      error => {
        console.error("Login error " + error)
      }
    );
  }

  logout() {
    const logoutRequest = {
        account: this.app.getAccountByHomeId(this.accountId)
    };
    this.app.logoutPopup(logoutRequest).then(() => {
        this.accountId = null;
        window.location.reload();
    });
  }

  isLoggedIn() {
    return true; // TODO: Oikea login käyttöön
      // console.log(this.accountId);
      // return !!this.accountId;
  }

  async getToken (scopes) {
    try {
      return await this.app.acquireTokenSilent(scopes);
    } catch (err) {
      try {
        return await this.app.acquireTokenPopup(scopes);
      } catch (err) {
        throw Error(err);
      }
    }
  }

  getAccount() {
      return this.app.getAccountByHomeId(this.accountId);
  }
}
