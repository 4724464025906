const fi = {
  common: {
    addNew: "Lisää uusi",
    close: "Sulje",
    search: "Etsi",
    cancel: "Peruuta",
    filter: "Suodata",
    saveChanges: "Tallenna muutokset",
    tba: "Ominaisuus tulossa",
    reason: "Syy: {reason}"
  },
  login: {
    loginButton: "Kirjaudu sisään",
    logOut: "Kirjaudu ulos",
    enablePopUps: "Salli pop-up-ikkunoiden näyttö selaimen niin pyytäessä. Pop-up ikkunaa tarvitaan sisäänkirjautumiseen."
  },
  receiptForm: {
    informationAboutTaxtCardTrip: "Selvitys taksikorttimatkasta",
    companyInformation: "Yrityksen tiedot",
    companyId: "Y-tunnus",
    companyName: "Yrityksen nimi",
    email: "Sähköposti",
    phoneNumber: "Puhelinnumero",
    tripInformation: "Matkan tiedot",
    customer: "Asiakas",
    pictureOfReceipt: "Kuva kuitista",
    iDontHaveAReceipt: "Minulla ei ole kuittia",
    travelDate: "Matkapäivä",
    startTime: "Aloitusaika",
    endTime: "Lopetusaika",
    region: "Maakunta",
    carNumber: "Auton numero",
    startingAddress: "Lähtöosoite",
    destinationAddress: "Kohdeosoite",
    deductible: "Omavastuu",
    orderId: "Tilaustunnus",
    startFee: "Lähtömaksu",
    waitFee: "Odotusmaksu",
    aidFee: "Avustuslisä",
    serviceFee: "Paarilisä",
    stairFee: "Porrasveto",
    fare: "Taksa",
    extraFees: "Lisät",
    total: "Yhteensä",
    addTrip: "Lisää matka",
    additionalInformation: "Lisätietoja",
    additionalInfoPlaceHolder: "Kirjoita mahdolliset lisätiedot tähän",
  }
};

export default fi;
