<template>
	<v-app>
    <!-- <SideMenu v-if="isLoggedIn" :user="user" ref="sideMenu"></SideMenu> -->
    <Header v-if="isLoggedIn" v-on:sideMenuToggled="toggleSideMenu"></Header>
    <v-main>
      <v-container v-if="isLoggedIn" fluid>
        <router-view></router-view>
      </v-container>
      <Login
        v-else
        @loggedIn="setUserContext"
      ></Login>
    </v-main>
    <v-footer padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} - <strong>Router Group</strong>
      </v-col>
    </v-footer>
	</v-app>
</template>

<script>
// import SideMenu from "./components/SideMenu/SideMenu";
import Header from "./components/Header/Header";
import Login from "./views/Login";

export default {
  name: "App",
  components: {
    Header,
    // SideMenu,
    Login
  },
  data: () => ({
    // user: null // TODO: Oikea login käyttöön, hardkoodattu user pois
    user: { username: "Local test user"}
  }),
  computed: {
    isLoggedIn () {
      return !!this.user;
    }
  },
  methods: {
    navigate (site) {
      this.$router.push({ name: site });
    },
    toggleSideMenu () {
      this.$refs.sideMenu.toggle();
    },
    async setUserContext () {
      this.user = this.$AuthService.getAccount();
    }
  }
};
</script>

<style>

.v-application {
   font-family: 'Merriweather', serif !important;
  }

div.v-input.theme--dark .v-input__slot {
	background-color: var(--v-primary-base) !important;
}
.even-item {
	background-color: #fafafa !important;
}

.odd-item {
	background-color: white !important;
}
</style>
